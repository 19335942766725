import React from 'react';

import { useNavigate } from 'react-router-dom';
import { PageHeader } from '@ant-design/pro-layout';
import { signOut, User } from 'firebase/auth';
import { SettingOutlined, LogoutOutlined } from '@ant-design/icons';

import { setAuthToken } from 'src/common/cookies';
import { colors, routes } from 'src/common/constants';
import { isSystemDark } from 'src/common/constants/colors';
import { useGetAuth } from 'src/common/customHooks/useGetAuth';

import { UserInfo } from './UserInfo';
import './style.css';

const logo = require('src/assets/img/logo.png');
const logoDark = require('src/assets/img/logoDark.png');

const LogoutButton: React.FC = () => {
  const navigate = useNavigate();
  const auth = useGetAuth();

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logged out');
        // Remove the authentication token from the cookie
        setAuthToken();
        navigate(routes.root);
      })
      .catch(console.error);
  };

  return (
    <div onClick={handleLogout} style={{ cursor: 'pointer' }} title='Logout'>
      <LogoutOutlined style={{ fontSize: '20px', color: 'white' }} />
    </div>
  );
};

interface HeaderProps {
  user: User | undefined;
}

const Header: React.FC<HeaderProps> = ({ user }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <PageHeader
      style={{
        justifyContent: 'space-between',
        backgroundColor: colors.primary,
      }}
      className='site-page-header headerCustom'
      backIcon={<img src={isSystemDark ? logoDark : logo} alt='logo' height={32} />}
      title={' '}
      onBack={() => {
        if (window.location.pathname !== routes.root) navigate(routes.root);
      }}
    >
      {user ? (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <div
            onClick={() => {
              navigate(routes.settings);
            }}
            style={{ cursor: 'pointer' }}
            className='settingsIcon'
            title='Settings'
          >
            <SettingOutlined style={{ fontSize: '20px', color: colors.white }} />
          </div>
          <UserInfo user={user} />
          <LogoutButton />
        </div>
      ) : null}
    </PageHeader>
  );
};

export { Header };
