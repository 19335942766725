import React from 'react';
import { Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { User } from 'firebase/auth';

import { Username } from './styled';
import { colors } from 'src/common/constants';

interface UserInfoProps {
  user: User;
}
const UserInfo: React.FC<UserInfoProps> = ({ user }: UserInfoProps) => {
  if (user == null) return null;
  const { email, displayName } = user;
  return (
    <div
      style={{
        marginRight: 15,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Avatar size='small' icon={<UserOutlined style={{ color: colors.white }} />} />
      <div
        style={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        {displayName != null && <Username>{displayName}</Username>}
        <Username>{email}</Username>
      </div>
    </div>
  );
};

export { UserInfo };
