export const isSystemDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

export const colors = {
  primary: isSystemDark ? '#465A81' : '#FFBA00',
  background: isSystemDark ? '#334669' : '#FFFFFF',
  footer: isSystemDark ? '#171E2C' : '#f5f5f5',
  text: isSystemDark ? '#ffffff' : '#000000',
  white: '#FFFFFF',
  grey1: '#FAFAFA',
  grey2: '#D9D9D9',
  grey3: '#B9B9B9',
  grey4: '#6D7278',
  success: '#52c41a',
  warning: '#faad14',
  error: '#f5222d',
  secondary: '#3986FA',
  tertiary: '#8E8E93',
  black: '#000000',
  sectionHeaderBackground: '#CACACC',
  saveMeBg: '#33A752',
  directionsLine: '#3BC802',
  grayBackground: '#EAEAEA',
  mapItemDescription: '#656565',
  bottomSheetText: '#707070',
  darkPrimary: '#465A81',
  darkSecondary: '#171E2C',
  darkSideMenuBackground: '#334669',
};
