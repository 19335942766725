import React, { useState } from 'react';
import { env } from 'src/common/utils';
import { apiUrls, axiosBaseQuery } from 'src/api';
import { AppCard, PageContainer } from 'src/components';
import { useVerifyEmailButton } from 'src/common/customHooks/useVerifyEmailButton';
import { CardBody, NotificationType, notificationTypesList } from './CardBody';

const maxBodyLength = 500;
const maxTitleLength = 30;
const Notification: React.FC = () => {
  const verifyEmailButton = useVerifyEmailButton();
  const [notificationType, setNotificationType] = useState<NotificationType>(NotificationType.INFO);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePostNotification = () => {
    if (title === '' || body === '') return;
    if (title.length > maxTitleLength)
      return alert(`Titolo deve essere minore di ${maxTitleLength} caratteri`);
    if (body.length > maxBodyLength)
      return alert(`Corpo notifica deve essere minore di ${maxBodyLength} caratteri`);

    const payload = {
      notification: {
        category: notificationType,
        title: `${notificationTypesList[notificationType].symbol} ${title}`,
        body,
        imageUrl,
      },
    };
    setLoading(true);
    axiosBaseQuery()({
      url: apiUrls.notifications.post,
      method: 'POST',
      data: payload,
    })
      .then((res) => {
        setLoading(false);
        setTitle('');
        setBody('');
        setImageUrl('');
        if (env !== 'prod') {
          console.log(res);
        }
        alert('Notifica pubblicata con successo!');
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        alert(`Errore durante la pubblicazione della notifica: ${err.data.message}`);
      });
  };

  return (
    <PageContainer loading={loading}>
      <AppCard
        title='Notifica'
        actions={
          verifyEmailButton == null
            ? [
                {
                  title: 'Pubblica Notifica',
                  onClick: handlePostNotification,
                  disabled: title.length === 0 || body.length === 0 || title.length > 30 || body.length > 100,
                },
              ]
            : []
        }
      >
        {verifyEmailButton != null ? (
          <>
            <p style={{ textAlign: 'center' }}>
              Per poter pubblicare una notifica devi prima verificare la tua email. Se non hai ricevuto una
              email di verifica, clicca sul bottone sottostante per inviarla nuovamente.
            </p>
            <p style={{ textAlign: 'center' }}>Una volta verificata la tua email, ricarica la pagina.</p>
            {verifyEmailButton}
          </>
        ) : (
          <CardBody
            title={title}
            setTitle={setTitle}
            body={body}
            setBody={setBody}
            imageURL={imageUrl}
            setImageURL={setImageUrl}
            notificationType={notificationType}
            setNotificationType={setNotificationType}
          />
        )}
      </AppCard>
    </PageContainer>
  );
};

export { Notification };
