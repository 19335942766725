import { FirebaseOptions } from 'firebase/app';

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyBUeK_UjwVZhg8idO6mcmQ3YmLOgfkQEyE',
  authDomain: 'luogosicuro-f4fc9.firebaseapp.com',
  projectId: 'luogosicuro-f4fc9',
  storageBucket: 'luogosicuro-f4fc9.appspot.com',
  messagingSenderId: '273845500685',
  appId: '1:273845500685:web:c22e31c0c553eba6da8e44',
  measurementId: 'G-Y8TMD1JC6Q',
};

export { firebaseConfig };
