import Cookies, { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

export const getCookie = (name: string) => cookies.get(name);
export const setCookie = (name: string, value: unknown, options?: CookieSetOptions) =>
  cookies.set(name, value, options);
export const removeCookie = (name: string) => cookies.remove(name);

export const authTokenCookieKey = 'authToken';

export const getAuthToken = () => getCookie(authTokenCookieKey);
export const setAuthToken = (token?: string) => {
  if (token) {
    setCookie(authTokenCookieKey, token, {
      expires: new Date(Date.now() + 3600 * 1000),
    });
  } else {
    removeCookie(authTokenCookieKey);
  }
};
