import React from 'react';
import { Select, SelectProps } from 'antd';
import { SpaceCustom } from '../../common/styled';

export type SelectorProps = SelectProps & {
  initialValue?: string;
};

const Selector: React.FC<SelectorProps> = ({
  options,
  initialValue,
  placeholder,
  onSelect,
  showSearch,
  style,
}) => (
  <Select
    showSearch={showSearch ?? false}
    defaultValue={initialValue}
    placeholder={placeholder}
    style={style}
    onChange={(value, option) => {
      // check if option is of type array return
      if (Array.isArray(option)) return;
      onSelect?.(value, option);
    }}
    listHeight={100}
    filterOption={(input, option) =>
      (option?.children as unknown as string).toLowerCase().includes(input.toLowerCase())
    }
  >
    {options?.map(({ label, value }) => (
      <Select.Option key={value} value={value}>
        {label}
      </Select.Option>
    ))}
  </Select>
);

interface Props extends SelectProps {
  label: string;
  initialValue?: string;
}
const DropdownSelector: React.FC<Props> = ({ label, ...props }: Props) => (
  <SpaceCustom direction='vertical'>
    {label}
    <Selector {...props} style={{ width: '100%' }} />
  </SpaceCustom>
);

export { DropdownSelector, Selector };
