import React from 'react';
import { Input } from 'antd';
import { InputProps } from '../../index.d';

const { Password } = Input;

type PartialProps = Partial<InputProps>;

const PasswordField: React.FC<PartialProps> = ({ value, placeholder, onChange }: PartialProps) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  return (
    <Password
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      visibilityToggle={{ visible: passwordVisible, onVisibleChange: setPasswordVisible }}
    />
  );
};

export { PasswordField };
