import styled from 'styled-components';
import { Layout } from 'antd';
import { colors } from 'src/common/constants/colors';

export const StyledFooter = styled(Layout.Footer)`
  text-align: center;
  padding: 0;
  height: 32px;
  color: ${colors.text} !important;
  background: ${colors.footer} !important;
  position: fixed;
  bottom: 0;
  width: 100%;
`;
