import React from 'react';
import { Button, Card, Space, Typography } from 'antd';
import { colors } from 'src/common/constants';

export interface CardAction {
  title: string;
  value?: string | number;
  onClick?: () => void;
  disabled?: boolean;
}

export interface CardActionElements {
  actionUIElement: React.ReactNode;
  action?: () => void;
}

interface AppCardProps<T = CardAction> {
  title: string;
  actions: T[];
  userActions?: CardActionElements[];
  style?: React.CSSProperties;
}

type Props = React.PropsWithChildren<AppCardProps>;

const AppCard: React.FC<Props> = ({ title, actions, userActions, style = {}, children }: Props) => {
  return (
    <Card
      type='inner'
      title={title}
      headStyle={{ backgroundColor: colors.primary, color: 'white' }}
      style={{ display: 'flex', flexDirection: 'column', ...style }}
      extra={
        <Space size='small'>
          {userActions?.map(({ action, actionUIElement }, idx) =>
            action != null ? (
              <Button key={idx} onClick={action}>
                {actionUIElement}
              </Button>
            ) : (
              <div key={idx}>{actionUIElement}</div>
            ),
          )}
        </Space>
      }
    >
      {children}
      {actions.map(({ title, onClick, value, disabled }, idx) => (
        <div key={`${title}_${idx}`} style={{ margin: '10px 0', width: '100%' }}>
          {onClick ? (
            <Button
              disabled={disabled}
              type='primary'
              onClick={onClick}
              style={{ width: '100%', backgroundColor: disabled ? colors.grey1 : colors.secondary }}
            >
              {title}
            </Button>
          ) : (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Typography.Text strong>{title}</Typography.Text>
              {value && <Typography.Text>{value}</Typography.Text>}
            </div>
          )}
        </div>
      ))}
    </Card>
  );
};

export { AppCard };
