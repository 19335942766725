import styled from 'styled-components';
import { Layout, Space } from 'antd';

import { colors } from './constants';

export const WhiteLayout = styled(Layout)`
  background: ${colors.background};
`;
export const PaddedContent = styled(Layout.Content)`
  padding: 16px;
  min-height: calc(100vh - 112px);
  height: 88.7vh;
`;

export const SpaceCustom = styled(Space)`
  width: 100%;
  margin: 10px 0;
`;
