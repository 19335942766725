import { Spin } from 'antd';
import styled from 'styled-components';

export const StyledLoader = styled(Spin)`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  & .anticon {
    font-size: 250% !important;
  }
`;
