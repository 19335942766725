import React from 'react';
import { Input } from 'antd';

import { TextFieldProps } from '../index.d';

import { DisabledField } from './DisabledField';
import { PasswordField } from './PasswordField';

const val = (value: string | number, isFloat: boolean) =>
  typeof value === 'number' ? value.toFixed(isFloat ? 2 : 0) : value;

const ActualInputContent: React.FC<TextFieldProps> = ({
  type,
  disabled,
  value = '',
  placeholder,
  onChange,
  icon,
  isFloat = true,
  isTextArea,
  onPastedText,
  style = {},
}: TextFieldProps) => {
  const onPaste = (pasted: string) => {
    if (onPastedText != null) {
      onPastedText(pasted);
      return;
    }
    const newVal = val(value, isFloat).length > 0 ? `${val(value, isFloat)}${pasted}` : pasted;
    // @ts-ignore-next-line
    onChange?.({ target: { value: newVal } });
  };

  const handlePaste: React.ClipboardEventHandler<HTMLInputElement> = (e) => {
    // Stop data actually being pasted into div
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData =
      e.clipboardData || (window as unknown as { clipboardData: DataTransfer }).clipboardData;
    const pastedData = clipboardData.getData('Text');

    // Do whatever with pasteddata
    setTimeout(() => {
      if (!pastedData || pastedData === '') return;
      if (type === 'number') {
        const text = pastedData.match(/[0-9]/g)?.join('');
        if (text) {
          onPaste(text);
        }
      } else onPaste(pastedData);
    }, 10);
  };

  if (disabled) {
    return <DisabledField value={val(value, isFloat)} isFloat={isFloat} />;
  }
  if (isTextArea === true) {
    return null;
  } else {
    if (type === 'password') {
      return <PasswordField value={value} placeholder={placeholder} onChange={onChange} />;
    }
    return (
      <Input
        type={type ?? 'text'}
        value={val(value, isFloat)}
        placeholder={placeholder}
        onChange={onChange}
        onPaste={handlePaste}
        prefix={icon ?? ''}
        style={style}
      />
    );
  }
};

export { ActualInputContent };
