import { useState } from 'react';
import { Button } from 'antd';
import { sendEmailVerification } from 'firebase/auth';
import { useGetAuth } from './useGetAuth';
import { colors } from '../constants';

const useVerifyEmailButton = () => {
  const auth = useGetAuth();
  const [loading, setLoading] = useState(false);

  const handleVerifyEmail = () => {
    const user = auth.currentUser;
    if (user) {
      setLoading(true);

      sendEmailVerification(user)
        .then(() => {
          setLoading(false);
          console.log('Email sent');
          alert('Email di verifica inviata con successo!');
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
          alert('Errore nel tentativo di invio di una email di verifica');
        });
    }
  };

  if (auth.currentUser?.emailVerified ?? false) return null;

  return (
    <Button
      type='primary'
      onClick={handleVerifyEmail}
      style={{ width: '100%', marginTop: 40, backgroundColor: colors.secondary }}
      loading={loading}
    >
      Verifica Email
    </Button>
  );
};

export { useVerifyEmailButton };
