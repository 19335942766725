import React, { useEffect } from 'react';

import { User } from 'firebase/auth';
import { useRoutes } from 'react-router-dom';

import { routes } from 'src/common/constants';
import { Header, Footer } from 'src/components';
import { setAuthToken } from 'src/common/cookies';
import { useGetAuth } from 'src/common/customHooks/useGetAuth';
import { PaddedContent, WhiteLayout } from 'src/common/styled';
import { Notification, Settings, Auth, Page404 } from 'src/Pages';
import { env } from './common/utils';

const useGetUser = () => {
  const auth = useGetAuth(env != 'prod');
  const [user, setUser] = React.useState(auth.currentUser);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (!user) {
        setAuthToken();
        return;
      }
      user
        .getIdToken()
        .then((idToken) => {
          // Set the authentication token in the cookie
          setAuthToken(idToken);
        })
        .catch(console.error);
    });

    return () => unsubscribe();
  }, []);

  return user ?? undefined;
};

interface MyRoutesProps {
  user: User | undefined;
}

const MyRoutes: React.FC<MyRoutesProps> = ({ user }: MyRoutesProps) => {
  const routesList = useRoutes([
    {
      path: routes.root,
      element: <Auth user={user} />,
      children: [
        { index: true, element: <Notification /> },
        { path: routes.settings, element: <Settings /> },
        { path: routes.notFound, element: <Page404 /> },
      ],
    },
    { path: '*', element: <Page404 /> },
  ]);
  return routesList;
};

const App: React.FC = () => {
  const user = useGetUser();

  return (
    <WhiteLayout>
      <Header user={user} />
      <PaddedContent>
        <MyRoutes user={user} />
      </PaddedContent>
      <Footer />
    </WhiteLayout>
  );
};

export default App;
