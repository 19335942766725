import { env } from 'src/common/utils';

const projectName = 'luogosicuro-f4fc9';

type EnvConfig = {
  API_URL: string;
};

type EnvConfigs = {
  dev: EnvConfig;
  local: EnvConfig;
  prod: EnvConfig;
};
const envConfig: EnvConfigs = {
  dev: {
    API_URL: `http://127.0.0.1:5001/${projectName}/us-central1`,
  },
  local: {
    API_URL: `http://127.0.0.1:5001/${projectName}/us-central1`,
  },
  prod: {
    API_URL: `https://us-central1-${projectName}.cloudfunctions.net`,
  },
};

export default envConfig[env] as EnvConfig;
