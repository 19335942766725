import { Result, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../common/constants';

const { Text } = Typography;

const Page404: React.FC = () => (
  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
    <Result status='404' />
    <h1 style={{ width: '100%', textAlign: 'center' }}>
      <Text>The page you were looking for does not exist.</Text>
    </h1>
    <Link to={routes.root} style={{ width: '100%', textAlign: 'center' }}>
      Go back home
    </Link>
  </div>
);

export { Page404 };
