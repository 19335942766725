import React from 'react';
import { InputProps } from '../../index.d';

const disabledStyle: React.CSSProperties = {
  border: '1px solid #d9d9d9',
  borderRadius: 2,
  boxSizing: 'border-box',
  padding: '4px 10px',
};

type PartialProps = Partial<InputProps>;

const DisabledField: React.FC<PartialProps> = ({ value = '', icon }: PartialProps) => (
  <div style={disabledStyle}>{`${value}${icon ? ` ${icon}` : ''}`}</div>
);

export { DisabledField };
