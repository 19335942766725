import { useEffect, useRef } from 'react';
import { connectAuthEmulator, getAuth } from 'firebase/auth';

export const useGetAuth = (connectToEmulator?: boolean) => {
  const auth = useRef(getAuth());
  useEffect(() => {
    if (connectToEmulator && connectToEmulator === true) {
      connectAuthEmulator(auth.current, 'http://localhost:9099');
    }
  }, []);
  return auth.current;
};
