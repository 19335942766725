/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { getAuthToken } from 'src/common/cookies';

import config from './config';

export const apiUrls = {
  base: config.API_URL,
  notifications: {
    post: '/notification',
  },
  topics: {
    setTopic: '/setUserTopic',
  },
};

export const API_URL = apiUrls.base;

export interface QueryParams {
  url: string;
  method?: 'GET' | 'POST' | 'DELETE' | 'PUT';
  data?: unknown;
  params?: Record<string, unknown>;
  mockRes?: unknown;
  Authorization?: string;
}

export interface ApiError {
  status: any;
  data: any;
  err: any;
}

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: apiUrls.base }) =>
  async ({ url, method, data, params, Authorization }: QueryParams) => {
    try {
      const token = getAuthToken();
      if (token == null) throw new Error('No token found');
      const authToken = `Bearer ${token}`;
      const headers = {
        'authorization': Authorization ?? authToken,
        'Content-Type': 'application/json',
      };
      if (method === 'POST' && data == null) {
        console.warn('You are trying to POST without data');
      }
      const result = await (method !== 'POST'
        ? axios({
            url: baseUrl + url,
            method,
            data,
            params,
            headers,
          })
        : axios.post(baseUrl + url, data, { headers }));
      return { data: result.data };
    } catch (axiosError: ApiError | any) {
      console.error(axiosError);
      const err = axiosError;
      throw {
        status: err.response?.status,
        data: err.response?.data || err.message,
        err,
      };
    }
  };
