import React from 'react';
import { StyledFooter } from './styled';

declare global {
  export interface Window {
    __RUNTIME_CONFIG__: {
      REACT_APP_VERSION: number;
      REACT_APP_ENV: 'dev' | 'local' | 'prod';
    };
  }
}

const Footer: React.FC = () => (
  <StyledFooter>
    <small>v{process.env.REACT_APP_VERSION}</small>
  </StyledFooter>
);
export { Footer };
