import React, { useEffect, useState } from 'react';
import { Input, Space } from 'antd';

const { TextArea } = Input;

import { SpaceCustom } from 'src/common/styled';

import { TextFieldProps, InputProps } from './index.d';

import { ActualInputContent } from './ActualInputField';
import { Selector, SelectorProps } from '../DropdownSelector';

type WithSelectorPrefixProps = { selectProps?: SelectorProps } & InputProps;
const WithSelectorPrefix: React.FC<WithSelectorPrefixProps> = ({
  selectProps,
  ...inputProps
}: WithSelectorPrefixProps) => {
  if (selectProps == null) return <ActualInputContent {...inputProps} />;
  return (
    <Space.Compact style={{ width: '100%' }}>
      <Selector {...selectProps} />
      <ActualInputContent {...inputProps} />
    </Space.Compact>
  );
};

const TextField: React.FC<TextFieldProps> = ({
  type,
  maxLength,
  value,
  placeholder,
  onChange,
  onPastedText,
  icon,
  label,
  isFloat = true,
  isTextArea,
  selectProps,
  ...props
}: TextFieldProps) => {
  const [disabled, setDisabled] = useState(false || props.disabled || props.readOnly || onChange == null);

  useEffect(() => {
    setDisabled(props.disabled || props.readOnly || onChange == null);
  }, [props.disabled, props.readOnly, onChange]);

  useEffect(() => {
    if (maxLength != null && value != null && value.toString().length > maxLength) {
      const val = value?.toString();
      onPastedText?.(val.slice(0, maxLength));
    }
  }, [maxLength, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SpaceCustom direction='vertical'>
      {label}
      {isTextArea === true && (
        <TextArea
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoSize={{ minRows: 3, maxRows: 5 }}
        />
      )}
      {isTextArea !== true && (
        <WithSelectorPrefix
          selectProps={selectProps}
          label={label}
          onChange={onChange}
          type={type}
          disabled={disabled}
          value={value}
          placeholder={placeholder}
          icon={icon}
          isFloat={isFloat}
        />
      )}
    </SpaceCustom>
  );
};

export { TextField };
