import React from 'react';
import { TextField } from 'src/components';

export enum NotificationType {
  INFO = 'info',
  WARNING = 'warning',
}

export const notificationTypesList = {
  [NotificationType.INFO]: {
    label: 'Info',
    symbol: String.fromCodePoint(0x2139), // 'ℹ️',
    value: NotificationType.INFO,
  },
  [NotificationType.WARNING]: {
    label: 'Allerta',
    symbol: String.fromCodePoint(0x26a0), // '⚠️',
    value: NotificationType.WARNING,
  },
};

export const notificationTypeOptions = Object.values(NotificationType).map(
  (type) => notificationTypesList[type],
);

type CardBodyProps = {
  title: string;
  setTitle: (title: string) => void;
  body: string;
  setBody: (body: string) => void;
  imageURL: string;
  setImageURL: (url: string) => void;
  notificationType: NotificationType;
  setNotificationType: (notificationType: NotificationType) => void;
  maxTitleLength?: number;
  maxBodyLength?: number;
};

const CardBody: React.FC<CardBodyProps> = ({
  title,
  setTitle,
  body,
  setBody,
  imageURL,
  setImageURL,
  notificationType,
  setNotificationType,
  maxTitleLength,
  maxBodyLength,
}: CardBodyProps) => {
  return (
    <>
      <TextField
        placeholder='Titolo Notifica'
        value={title}
        maxLength={maxTitleLength}
        selectProps={{
          options: notificationTypeOptions,
          initialValue: notificationTypesList[NotificationType.INFO].value,
          onSelect: (value, option) => {
            if (!option) return;
            if (typeof option.value === 'string') {
              setNotificationType(value as NotificationType);
            }
          },
        }}
        icon={notificationTypesList[notificationType].symbol}
        onChange={({ target }) => setTitle(target.value)}
      />
      <TextField
        label='Corpo notifica'
        placeholder='Inserisci il corpo della notifica'
        value={body}
        isTextArea
        maxLength={maxBodyLength}
        onChange={({ target }) => {
          setBody(target.value);
        }}
      />
      <TextField
        label='URL Immagine'
        placeholder={"Inserisci l'url dell'immagine"}
        value={imageURL}
        onChange={({ target }) => {
          setImageURL(target.value);
        }}
      />
    </>
  );
};

export { CardBody };
