import React, { useEffect, useState } from 'react';

import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { signOut, updatePassword } from 'firebase/auth';

import { env } from 'src/common/utils';
import { apiUrls, axiosBaseQuery } from 'src/api';
import { setAuthToken } from 'src/common/cookies';
import { colors, routes } from 'src/common/constants';
import { useGetAuth } from 'src/common/customHooks/useGetAuth';
import { useVerifyEmailButton } from 'src/common/customHooks/useVerifyEmailButton';
import { AppCard, PageContainer, TextField, DropdownSelector } from 'src/components';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const auth = useGetAuth();
  const verifyEmailButton = useVerifyEmailButton();
  const [newPassword, setNewPassword] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userTopic, setUserTopic] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    auth.currentUser?.getIdTokenResult().then((idTokenResult) => {
      if (env !== 'prod') {
        console.log('idTokenResult', idTokenResult);
      }
      const admin = idTokenResult.claims.admin ?? { admin: false };
      if (idTokenResult.claims.admin && typeof admin === 'boolean') {
        setIsAdmin(idTokenResult.claims.admin);
      }
    });
  }, []);

  const handleChangePassword = () => {
    const user = auth.currentUser;
    if (user && newPassword !== '') {
      setLoading(true);
      updatePassword(user, newPassword)
        .then(() => {
          setNewPassword('');
          setLoading(false);
          console.log('Password updated');
          alert('Password modificata con successo!');
        })
        .catch((err) => {
          setNewPassword('');
          console.error(err);
          setLoading(false);
        });
    }
  };
  const handleSaveTopic = () => {
    if (userEmail === '' || userTopic === '') return;
    // Validate email using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) return alert('Email non valida');

    setLoading(true);
    axiosBaseQuery()({
      url: apiUrls.topics.setTopic,
      method: 'POST',
      data: { targetEmail: userEmail, topic: userTopic },
    })
      .then((res) => {
        setLoading(false);
        setUserEmail('');
        setUserTopic('');
        if (env !== 'prod') {
          console.log(res);
        }
        alert(`Topic impostato con successo! utenza: ${userEmail}`);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        alert(`Errore durante l'impostazione del topic ${userTopic}: ${err.data.message}`);
      });
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        console.log('Logged out');
        // Remove the authentication token from the cookie
        setAuthToken();
        navigate(routes.root);
      })
      .catch(console.error);
  };

  return (
    <PageContainer loading={loading}>
      <AppCard
        title='Cambia Password'
        actions={[{ title: 'Cambia Password', onClick: handleChangePassword }]}
      >
        <TextField
          type='password'
          label='Nuova password'
          placeholder=''
          value={newPassword}
          onChange={({ target }) => {
            setNewPassword(target.value);
          }}
        />
      </AppCard>
      {isAdmin && (
        <AppCard
          title='Set users topic'
          style={{ marginTop: 40 }}
          actions={[{ title: 'Salva', onClick: handleSaveTopic }]}
        >
          <TextField
            type='email'
            label='User email'
            placeholder=''
            value={userEmail}
            onChange={({ target }) => {
              setUserEmail(target.value);
            }}
          />
          <DropdownSelector
            label='Topic'
            placeholder='Seleziona un topic'
            value={userTopic}
            onSelect={(_value, option) => {
              if (!option) return;
              if (typeof option.value === 'string') {
                setUserTopic(option.value);
              }
            }}
            options={[
              { label: 'All', value: 'all' },
              { label: 'Perito', value: 'perito' },
              ...(env !== 'prod' ? [{ label: 'Test', value: 'test' }] : []),
            ]}
          />
        </AppCard>
      )}
      {verifyEmailButton}
      <Button
        type='primary'
        onClick={handleLogout}
        style={{ width: '100%', marginTop: 40, backgroundColor: colors.error }}
      >
        Logout
      </Button>
    </PageContainer>
  );
};

export { Settings };
