import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { AppCard, PageContainer, TextField } from 'src/components';
import { useGetAuth } from 'src/common/customHooks/useGetAuth';
import { env } from 'src/common/utils';
import { setAuthToken } from 'src/common/cookies';

interface LoginProps {
  onLoadingBegin: () => void;
  onLoadingEnd: () => void;
}
const Login: React.FC<LoginProps> = ({ onLoadingBegin, onLoadingEnd }: LoginProps) => {
  const auth = useGetAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Setup keypress listener
  React.useEffect(() => {
    // On press enter, login
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleLogin();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  // Login
  const handleLogin = () => {
    onLoadingBegin();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        if (env != 'prod') {
          console.log('Logged in', user);
        }
        onLoadingEnd();
        user
          .getIdToken()
          .then((idToken) => {
            // Set the authentication token in the cookie
            setAuthToken(idToken);
          })
          .catch(console.error);
      })
      .catch((error) => {
        onLoadingEnd();
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        console.error(error);
        alert('Credenziali errate');
      });
  };

  return (
    <PageContainer>
      <AppCard title='Login' actions={[{ title: 'Login', onClick: handleLogin }]}>
        <TextField
          type='email'
          label='email'
          placeholder='email'
          value={email}
          onChange={({ target }) => {
            setEmail(target.value);
          }}
        />
        <TextField
          type='password'
          label='Password'
          placeholder='Password'
          value={password}
          onChange={({ target }) => {
            setPassword(target.value);
          }}
        />
      </AppCard>
    </PageContainer>
  );
};

export { Login };
