import { Col, Row } from 'antd';
import React, { PropsWithChildren } from 'react';
import { Loader } from '../Loader';

const spans = {
  xs: 24,
  md: 12,
  lg: 12,
  xl: 18,
};

const offsets = {
  xs: 0,
  md: 6,
  lg: 6,
  xl: 3,
};

interface Props {
  loading?: boolean;
}

type PageContainerProps = PropsWithChildren<Props>;

const PageContainer: React.FC<PageContainerProps> = ({ children, loading }: PageContainerProps) => {
  return (
    <Row>
      <Col
        xs={{ span: spans.xs, offset: offsets.xs }}
        md={{ span: spans.md, offset: offsets.md }}
        lg={{ span: spans.lg, offset: offsets.lg }}
        xl={{ span: spans.xl, offset: offsets.xl }}
      >
        {children}
      </Col>
      {loading === true && <Loader />}
    </Row>
  );
};

export { PageContainer };
