import React from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';

import { StyledLoader } from './styled';

interface LoaderProps {
  height?: number;
  message?: string;
}
const Loader: React.FC<LoaderProps> = ({ message }: LoaderProps) => (
  <StyledLoader
    style={{
      height: '88.7vh',
      position: 'absolute',
      top: 79,
      left: 0,
      right: 0,
      zIndex: 9000,
      width: '100vw',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    }}
    indicator={
      <>
        <Loading3QuartersOutlined spin />
        {message}
      </>
    }
  />
);

export { Loader };
