import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from 'src/components';
import { User } from 'firebase/auth';
import { Login } from '../Login';

const Redirect: React.FC<{ url: string }> = ({ url }: { url: string }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return <Loader />;
};

export interface OutletContext {
  user: User;
}

interface AuthProps {
  user: User | undefined;
}

const Auth: React.FC<AuthProps> = ({ user }: AuthProps) => {
  const [showLoader, setShowLoader] = useState(false);

  if (process.env.REACT_APP_USE_MOCK === 'true') return <Outlet context={{ user }} />;
  if (showLoader) return <Loader />;
  return user == null ? (
    <Login onLoadingBegin={() => setShowLoader(true)} onLoadingEnd={() => setShowLoader(false)} />
  ) : (
    <Outlet context={{ user }} />
  );
};

export { Auth, Redirect };
